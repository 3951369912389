import React from "react"
import { Typography } from "antd"
import { useOutletContext } from "react-router-dom"

import { Value } from "@components/Text"
import {
  TRANSACTION_STATUS,
  TRANSACTION_TYPE_LABEL_MAP,
} from "@components/Domain"
import { getUsDateFromIsoDate } from "@components/Date"

import renderStatus from "../../DistributionsTable/helpers/renderStatus"
import getAmountColumnProps from "./getAmountColumnProps"
import useLiaisonColumnProps from "./useLiaisonColumnProps"
import computeStatus from "./computeStatus"

const { Text, Link: TextLink } = Typography


const renderRejected = (status, value) => {
  const isRejected = status === TRANSACTION_STATUS.REJECTED

  return (
    <Text delete={isRejected}>
      {value}
    </Text>
  )
}


const useColumnsConfig = (
  openInvestmentDrawer,
  openTransactionDrawer
) => {
  const {
    organization,
    getProjectName,
    getSponsorName,
    hasMultipleSponsors,
    isInvalidBankAccountTransaction,
  } = useOutletContext()

  const { name: organizationName } = organization

  const liaisonColumnProps = useLiaisonColumnProps(renderRejected)

  const dateColumnProps = {
    key: "date",
    render: (date, { status }) =>
      renderRejected(status, <Value value={getUsDateFromIsoDate(date)} />),
  }

  const investmentNameColumnProps = {
    key: "investmentName",
    title: "Profile",
    render: (value, { status, investmentId }) =>
      renderRejected(status,
        <TextLink strong onClick={() => openInvestmentDrawer(investmentId)}>
          {value}
        </TextLink>
      ),
    isSearchable: true,
  }

  const projectColumnProps = {
    key: "projectId",
    title: "Project",
    compute: ({ projectId }) => getProjectName(projectId),
    isFilterEnabled: true
  }

  const sponsorColumnProps = {
    key: "sponsorId",
    title: "Sponsor",
    compute: ({ sponsorId }) => getSponsorName(sponsorId),
    isFilterEnabled: true
  }

  const typeColumnProps = {
    key: "type",
    compute: ({ type }) => TRANSACTION_TYPE_LABEL_MAP[type],
    isFilterEnabled: true
  }

  const amountInColumnProps = getAmountColumnProps(organizationName, openTransactionDrawer, isInvalidBankAccountTransaction)

  const amountOutColumnProps = getAmountColumnProps(organizationName, openTransactionDrawer, isInvalidBankAccountTransaction, false)

  const statusColumnProps = {
    key: "status",
    title: "Status",
    align: "right",
    compute: transaction => computeStatus(transaction),
    render: (label, { status, retryTransactionId }) => renderStatus(label, { status, retryTransactionId }),
    isFilterEnabled: true
  }

  return [
    { ...dateColumnProps, fixed: "left", width: 100 },
    { ...investmentNameColumnProps, fixed: "left", width: 300, hasTruncation: true },
    { ...projectColumnProps, width: "auto" },
    ...(
      hasMultipleSponsors
        ? [{ ...sponsorColumnProps, width: "auto" }]
        : []
    ),
    { ...liaisonColumnProps, width: "auto", widthAutoPadding: 40 },
    { ...typeColumnProps, width: "auto" },
    { ...amountInColumnProps, width: 140 },
    { ...amountOutColumnProps, width: 140 },
    { ...statusColumnProps, width: 140 },
  ]
}

export default useColumnsConfig
