
const ENTITY_NAME_KEY = "Entity Name"

const getEntityNames = project => {
  const { investmentSubjects: properties } = project

  const propertiesTags = properties
    .map(({ tags }) => tags)
    .reduce((a, b) => a.concat(b), [])

  const entityNames = propertiesTags
    .filter(({ label }) => label === ENTITY_NAME_KEY)
    .filter(({ value }) => !!value && value.length > 0)
    .map(({ value }) => value)

  const uniqueEntityNames = [
    ...new Set(entityNames)
  ]

  return uniqueEntityNames.join(", ")
}

export default getEntityNames
