import { useState, useMemo, useRef, useCallback } from "react"
import { usePapaParse } from "react-papaparse"
import { useOutletContext } from "react-router-dom"
import dayjs from "dayjs"
import keyBy from "lodash.keyby"
import chunk from "lodash.chunk"
import sortBy from "lodash.sortby"

import { FUNDING } from "@api/services/transactions/shapes/TransactionTypeEnum"
import authenticate from "@components/Authorization/authenticate/authenticate"
import authorizeUser from "@components/Authorization/authorize/authorizeUser"
import computeStatus from "@modules/backstage/transactions/TransactionsTable/helpers/computeStatus"
import computeInvestor from "@modules/backstage/investments/InvestmentsTable/helpers/computeInvestor"
import operationRequest from "@components/Authorization/request/operationRequest"
import { useAppContext } from "@components/AppContext"
import { downloadContent } from "@components/Import"
import { getCurrencyValue } from "@components/Amount"
import { getUsDateFromIsoDate } from "@components/Date"
import { listProjectsOperation } from "@api/services/backstage"
import { indexTransactionsOperation } from "@api/services/transactions"
import { getIndexOperationParameters } from "@modules/backstage/transactions/TransactionsTable/helpers/useIndexOperationParameters"
import { indexProjectInvestmentsOperation } from "@api/services/investments"
import { TRANSACTION_TYPE_LABEL_MAP, PROFILE_TYPES_LABELS } from "@components/Domain"

const LABEL_DATE = "Date"
const LABEL_AMOUNT = "Amount"
const LABEL_STATUS = "Status"
const LABEL_CHANNEL = "Channel"
const LABEL_PROFILE = "Investor Profile"
const LABEL_PROJECT_ID = "Project ID"
const LABEL_PROFILE_TYPE = "Profile Type"
const LABEL_PROJECT_NAME = "Project Name"
const LABEL_INVESTMENT_ID = "Investment ID"
const LABEL_INVESTOR_EMAIL = "Investor Email"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_TRANSACTION_TYPE = "Transaction Type"
const LABEL_ORGANIZATION_NAME = "Organization Name"

const fileName = "distributions_report"

const CONCURRENT_CHUNK_SIZE = 3


const concurrently = (items, callback) =>
  Promise.all(items.map(item => callback(item)))


const useDistributionsReport = (onReady) => {
  const shouldStopRef = useRef(false)
  const [ progress, setProgress ] = useState(0)

  const { jsonToCSV } = usePapaParse()
  const { organizations } = useOutletContext()
  const { showErrorMessage } = useAppContext()

  const sortedOrganizations = useMemo(() =>
    sortBy(organizations)
  , [ organizations ])

  const operationsCount = useMemo(() =>
    organizations.length
  , [ organizations ])

  const getOranizationAuthorization = useCallback(async (organizationId) => {
    const authenticationJwt = await authenticate(showErrorMessage, true)
    const [ authorizationJwt ] = await authorizeUser(authenticationJwt, organizationId)

    return authorizationJwt
  }, [ showErrorMessage ])

  const getOragnizationProjects = useCallback(async (authorization) => {
    const parameters = {
      operation: listProjectsOperation,
      headers: { authorization },
    }

    const { data: projects } = await operationRequest(parameters)

    return sortBy(projects)
  }, [])

  const getOragnizationTransactions = useCallback(async (authorization, date) => {
    const endDate = dayjs(date).endOf('month')
    const startDate = dayjs(date).startOf('month')

    const parameters = getIndexOperationParameters({
      dateRange: [startDate, endDate]
    })

    parameters.limit = 999

    const { data: transactions } = await operationRequest({
      operation: indexTransactionsOperation,
      headers: { authorization },
      parameters,
    })

    return transactions
  }, [])

  const getOrganizationInvestmentsMap = useCallback(async (authorization, projectId) => {
    const parameters = {
      limit: 999,
      projectId,
    }

    const { data: investments } = await operationRequest({
      operation: indexProjectInvestmentsOperation,
      headers: { authorization },
      parameters
    })

    return keyBy(investments, "id")
  }, [])

  const getProjectDistributions = useCallback((targetProjectId, transactions) =>
    transactions
      .filter(({ type }) => type !== FUNDING)
      .filter(({ projectId }) => projectId === targetProjectId)
      .filter(({ retryTransactionId }) => !retryTransactionId)
  , [])

  const computeRow = useCallback((organization, project, investmentMap, distribution) => {
    const {
      id: organizationId,
      name: organizationName,
    } = organization

    const {
      id: projectId,
      name: projectName,
    } = project

    const {
      type,
      date,
      amount,
      isExternal,
      investmentId,
      investmentName,
    } = distribution

    const investment = investmentMap[investmentId]

    const profile = investment
      ? computeInvestor(investment)
      : investmentName

    let email = ""
    let profileType = ""

    if (investment) {
      email = investment.investor.email
      profileType = investment.profileType
    }

    const channel = isExternal
      ? "Backfill"
      : "Dwolla"

    const row = {
      [LABEL_ORGANIZATION_ID]: organizationId,
      [LABEL_ORGANIZATION_NAME]: organizationName,
      [LABEL_PROJECT_ID]: projectId,
      [LABEL_PROJECT_NAME]: projectName,
      [LABEL_INVESTOR_EMAIL]: email,
      [LABEL_INVESTMENT_ID]: investmentId,
      [LABEL_PROFILE]: profile,
      [LABEL_PROFILE_TYPE]: PROFILE_TYPES_LABELS[profileType],
      [LABEL_TRANSACTION_TYPE]: TRANSACTION_TYPE_LABEL_MAP[type],
      [LABEL_DATE]: getUsDateFromIsoDate(date),
      [LABEL_AMOUNT]: getCurrencyValue(amount),
      [LABEL_CHANNEL]: channel,
      [LABEL_STATUS]: computeStatus(distribution),
    }

    return row
  }, [])

  const start = useCallback(async (isoDate) => {
    setProgress(0)
    shouldStopRef.current = false

    const rows = []

    let index = 1

    for (const organization of sortedOrganizations) {
      if (shouldStopRef.current) {
        return
      }

      const { id: organizationId } = organization
      const authorization = await getOranizationAuthorization(organizationId)

      const projects = await getOragnizationProjects(authorization)
      const transactions = await getOragnizationTransactions(authorization, isoDate)

      const chunks = chunk(projects, CONCURRENT_CHUNK_SIZE)

      for (const chunkItem of chunks) {
        if (shouldStopRef.current) break

        await concurrently(chunkItem, async project => {
          if (shouldStopRef.current) {
            return
          }

          const { id: projectId } = project

          const investmentMap = await getOrganizationInvestmentsMap(authorization, projectId)
          const projectDistributions = getProjectDistributions(projectId, transactions)

          for (const distribution of projectDistributions) {
            if (shouldStopRef.current) {
              break
            }

            const row = computeRow(organization, project, investmentMap, distribution)
            rows.push(row)
          }
        })
      }

      setProgress(Math.ceil(index * 100 / operationsCount))
      index++
    }

    if (!shouldStopRef.current) {
      const content = jsonToCSV(rows)
      downloadContent(`${fileName}.csv`, content, 'text/csv', true)
      onReady()
    }
  }, [
    sortedOrganizations,
    operationsCount,
    getOranizationAuthorization,
    getOragnizationProjects,
    getOragnizationTransactions,
    getOrganizationInvestmentsMap,
    getProjectDistributions,
    computeRow,
    jsonToCSV,
    onReady
  ])

  const stop = useCallback(() => {
    shouldStopRef.current = true
  }, [])

  return [ progress, start, stop ]
}

export default useDistributionsReport
