import React from "react"
import PropTypes from "prop-types"
import { App, Button } from "antd"
import { ReloadOutlined } from "@ant-design/icons"
import { useOutletContext } from "react-router-dom"

import { hasAccess } from "@components/Authorization"
import { getConfig } from "@components/Config"
import { useAppContext } from "@components/AppContext"
import { TRANSACTION_STATUS } from "@components/Domain"
import {
  ReadTransactionOutputShape,
  retryFundingTransactionOperation,
  retryDistributionTransactionOperation,
} from "@api/services/transactions"

const OPERATIONS_MAP = {
  [TRANSACTION_STATUS.ERROR]: retryDistributionTransactionOperation,
  [TRANSACTION_STATUS.FAILED]: retryDistributionTransactionOperation,
}

const PLATFORM_NAME = getConfig("PLATFORM_SHORT_NAME")

const FUNDING_TYPE = 'FUNDING'

const LABEL_RETRY = "Retry"
const LABEL_TITLE = "Re-submit Transaction"
const LABEL_RETRY_MESSAGE = "Transaction submitted for processing"
const LABEL_DISTRIBUTION_RETRY = "Retry Distribution"

const DISTRIBUTION_ORGANIZATION_INSUFFICIENT_FUNDS_MESSAGE = `By clicking
"Retry" below, ${PLATFORM_NAME} will attempt to resend distributions to Dwolla for
processing. Due to the error, funds have not been debited from the distribution
account. If the issue has been addressed, funds will be resent to the investor
bank account. In addition, investors will be notified via email that
distributions are on their way to their linked bank accounts.`

const DISTRIBUTION_INVALID_ORGANIZATION_ACCOUNT_MESSAGE = `Before proceeding,
ensure that you have connected a new valid distributions bank account. Due to
the error, funds have not been debited from the original distributions account.
By clicking "Retry", ${PLATFORM_NAME} will attempt to resend distributions to
Dwolla for processing. If the issue has been addressed, funds will be resent to
the investor bank account. In addition, investors will be notified via email
that distributions are on their way to their linked bank accounts.`

const FUNDING_INVALID_ORGANIZATION_ACCOUNT_MESSAGE = `By clicking "Retry",
${PLATFORM_NAME} will attempt to resend the transaction from the Dwolla balance
to the [ORGANIZATION_NAME] funding bank account for processing. Funds have
already been debited from the investor's account. If the organization account
information is correct, funds will be sent from the Dwolla balance to the
updated organization bank account.`

const INVALID_ACCOUNT_CODE = 'R03'
const INSUFFICIENT_FUNDS_CODES = [ "R01", "R09" ]

const DISTRIBUTION_CONFIRM_PARAMS_MAP = {
  'R01': {
    title: LABEL_DISTRIBUTION_RETRY,
    message: DISTRIBUTION_ORGANIZATION_INSUFFICIENT_FUNDS_MESSAGE
  },
  'R09': {
    title: LABEL_DISTRIBUTION_RETRY,
    message: DISTRIBUTION_ORGANIZATION_INSUFFICIENT_FUNDS_MESSAGE
  },
  'R02': {
    title: LABEL_TITLE,
    message: DISTRIBUTION_INVALID_ORGANIZATION_ACCOUNT_MESSAGE
  },
  [INVALID_ACCOUNT_CODE]: {
    title: LABEL_TITLE,
    message: DISTRIBUTION_INVALID_ORGANIZATION_ACCOUNT_MESSAGE
  }
}

const FUNDING_CONFIRM_PARAMS = {
  title: LABEL_TITLE,
  message: FUNDING_INVALID_ORGANIZATION_ACCOUNT_MESSAGE
}


const isInsufficientFundsBankAccountCode = (statusReason) => {
  if (!statusReason) {
    return false
  }

  let reason

  try {
    reason = JSON.parse(statusReason)

  } catch (e) {
    return false
  }

  const { code } = reason

  return INSUFFICIENT_FUNDS_CODES.includes(code)
}

const getConfirmParams = ({
  isFunding,
  statusReason,
  isInvalidBankAccount
}) => {
  if (!statusReason) {
    return
  }

  const { code } = JSON.parse(statusReason)
  const isInvalidAccountCode = code === INVALID_ACCOUNT_CODE
  const isValidBankAccount = !isInvalidBankAccount

  const isInvalidInvestorSource =
    isInvalidAccountCode &&
    isValidBankAccount

  // NOTE: If investor funding source is invalid, investor has to fix it via
  //       investor portal and create new transaction. If investor distribution
  //       source is invalid, investor has to update source via investor portal
  //       and distribution transaction is retried automatically.
  if (isInvalidInvestorSource) {
    return
  }

  const params = isFunding
    ? FUNDING_CONFIRM_PARAMS
    : DISTRIBUTION_CONFIRM_PARAMS_MAP[code]

  if (!params) {
    return
  }

  return { ...params, code }
}


const getRetryOperation = (status, isFunding) => {
  if (isFunding) {
    return retryFundingTransactionOperation
  }

  return OPERATIONS_MAP[status]
}


const getIsProjectBankAccountValid = (project, bankAccountsMap = {}) => {
  const { bankAccountId } = project

  const bankAccount = bankAccountsMap[bankAccountId]

  if (!bankAccount) {
    return true
  }

  const { isInvalid } = bankAccount

  return !isInvalid
}


const isBankAccountInvalid = (bankAccounts, receiverSourceId) => {
  const bankAccount = bankAccounts
    .find(({ sourceId }) => sourceId === receiverSourceId)

  const isDeleted = !bankAccount

  if (isDeleted) {
    return true
  }

  return bankAccount.isInvalid
}


const TransactionRetry = ({
  transaction,
  onAfterRetry
}) => {
  const { modal } = App.useApp()

  const {
    request,
    getOrganization,
    showSuccessMessage,
  } = useAppContext()

  const {
    getProject,
    bankAccounts,
    bankAccountsMap,
    isInvalidBankAccountTransaction,
  } = useOutletContext()

  const { name: organizationName } = getOrganization()

  const isInvalidBankAccount = isInvalidBankAccountTransaction(transaction)

  const {
    id,
    type,
    status,
    projectId,
    isApproved,
    statusReason,
    receiverSourceId,
    retryTransactionId
  } = transaction

  const project = getProject(projectId)

  const isFunding = type === FUNDING_TYPE

  const retryOperation = getRetryOperation(status, isFunding)

  const canRetry = hasAccess(['transactions-write'])

  const isPending = status === TRANSACTION_STATUS.PENDING

  const isRetried = !!retryTransactionId

  const isNotRetriableStatus = !retryOperation

  const isProjectBankAccountValid = getIsProjectBankAccountValid(project, bankAccountsMap)

  const isInsufficientFundsInvestorBankAccount =
    isFunding &&
    isInsufficientFundsBankAccountCode(statusReason)

  const isTransactionReceiverSourceInvalid = isBankAccountInvalid(bankAccounts, receiverSourceId)

  const isRetriableFundingTransaction =
    isFunding &&
    isProjectBankAccountValid &&
    isTransactionReceiverSourceInvalid

  if (!isRetriableFundingTransaction) {
    return null
  }

  if (isRetried) {
    return null
  }

  if (!canRetry) {
    return null
  }

  if (isPending) {
    return null
  }

  if (!isApproved) {
    return null
  }

  if (isNotRetriableStatus) {
    return null
  }

  if (isInsufficientFundsInvestorBankAccount) {
    return null
  }

  const confirmParams = getConfirmParams({
    isFunding,
    statusReason,
    isInvalidBankAccount
  })

  if (!confirmParams) {
    return null
  }

  const { title, message } = confirmParams
  const content = message
    .replaceAll('[ORGANIZATION_NAME]', organizationName)

  const onClick = () => {
    modal.confirm({
      okText: LABEL_RETRY,
      title,
      content,
      onOk() {
        return request(retryOperation, { id })
          .then(({ data }) => onAfterRetry(data))
          .then(() => {
            showSuccessMessage(LABEL_RETRY_MESSAGE)
          })
      },
      onCancel() {}
    })
  }

  return (
    <Button
      icon={<ReloadOutlined />}
      type="text"
      size="small"
      onClick={onClick}
    >
      {LABEL_RETRY}
    </Button>
  )
}

TransactionRetry.propTypes = {
  transaction: ReadTransactionOutputShape.isRequired,
  onAfterRetry: PropTypes.func.isRequired,
}

export default TransactionRetry
