import { useState, useMemo, useRef } from "react"
import { usePapaParse } from "react-papaparse"
import { useOutletContext } from "react-router-dom"

import authenticate from "@components/Authorization/authenticate/authenticate"
import authorizeUser from "@components/Authorization/authorize/authorizeUser"
import operationRequest from "@components/Authorization/request/operationRequest"
import { useAppContext } from "@components/AppContext"
import { downloadContent } from "@components/Import"
import { getCurrencyValue } from "@components/Amount"
import { getUsDateFromIsoDate } from "@components/Date"
import { listProjectsOperation } from "@api/services/backstage"
import { readTransactionReportOperation } from "@api/services/transactions"

const LABEL_PROJECT_ID = "Project ID"
const LABEL_PROJECT_NAME = "Project Name"
const LABEL_EQUITY_RAISED = "Total Equity Raised"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_ORGANIZATION_NAME = "Organization Name"
const LABEL_TOTAL_DISTRIBUTIONS = "Total Distributions"
const LABEL_PROJECT_GO_LIVE_DATE = "Go Live Date"

const fileName = "projects_report"


const useProjectsReport = (onReady) => {
  const [progress, setProgress] = useState(0)
  const shouldStopRef = useRef(false)

  const { jsonToCSV } = usePapaParse()
  const { organizations } = useOutletContext()
  const { showErrorMessage } = useAppContext()

  const operationsCount = useMemo(() =>
    organizations.length
  , [organizations])

  const start = async () => {
    setProgress(0)
    shouldStopRef.current = false

    const rows = []

    let index = 1

    for (const organization of organizations) {
      if (shouldStopRef.current) {
        return
      }

      const {
        id: organizationId,
        name: organizationName,
      } = organization

      const authenticationJwt = await authenticate(showErrorMessage, true)
      const [ authorization ] = await authorizeUser(authenticationJwt, organizationId)

      const headers = { authorization }

      const { data: projects } = await operationRequest({
        operation: listProjectsOperation,
        headers,
      })

      for (const project of projects) {
        const {
          id: projectId,
          name: projectName,
          openDate,
        } = project

        const parameters = { projectId }

        const { data: transactionReport } = await operationRequest({
          operation: readTransactionReportOperation,
          headers,
          parameters,
        })

        const {
          equityRaisedAmount,
          distributionsAmount
        } = transactionReport

        const equityRaisedCurrencyAmount = getCurrencyValue(equityRaisedAmount)
        const distributionsCurrencyAmount = getCurrencyValue(distributionsAmount)

        const row = {
          [LABEL_ORGANIZATION_ID]: organizationId,
          [LABEL_ORGANIZATION_NAME]: organizationName,
          [LABEL_PROJECT_ID]: projectId,
          [LABEL_PROJECT_NAME]: projectName,
          [LABEL_PROJECT_GO_LIVE_DATE]: getUsDateFromIsoDate(openDate),
          [LABEL_EQUITY_RAISED]: equityRaisedCurrencyAmount,
          [LABEL_TOTAL_DISTRIBUTIONS]: distributionsCurrencyAmount,
        }

        rows.push(row)
      }

      setProgress(Math.ceil(index * 100 / operationsCount))
      index++
    }

    if (!shouldStopRef.current) {
      const content = jsonToCSV(rows)
      downloadContent(`${fileName}.csv`, content, 'text/csv', true)
      onReady()
    }
  }

  const stop = () =>
    shouldStopRef.current = true

  return [ progress, start, stop ]
}

export default useProjectsReport
