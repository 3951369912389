import getOperation from "../../helpers/getOperation"

import ApproveTransactionOutputShape from "./ApproveTransactionOutputShape"
import CertifyMyCustomerBeneficialOwnershipOutputShape from "./CertifyMyCustomerBeneficialOwnershipOutputShape"
import ConnectCustomSourceOutputShape from "./ConnectCustomSourceOutputShape"
import ConnectSourceOutputShape from "./ConnectSourceOutputShape"
import CreateBankAccountOutputShape from "./CreateBankAccountOutputShape"
import CreateDistributionTransactionOutputShape from "./CreateDistributionTransactionOutputShape"
import CreateImpersonatedReceiveOnlyBusinessCustomerOutputShape from "./CreateImpersonatedReceiveOnlyBusinessCustomerOutputShape"
import CreateImpersonatedUnverifiedBusinessCustomerOutputShape from "./CreateImpersonatedUnverifiedBusinessCustomerOutputShape"
import CreateImpersonatedUnverifiedPersonalCustomerOutputShape from "./CreateImpersonatedUnverifiedPersonalCustomerOutputShape"
import CreateReceiveOnlyBusinessCustomerOutputShape from "./CreateReceiveOnlyBusinessCustomerOutputShape"
import CreateUnverifiedBusinessCustomerOutputShape from "./CreateUnverifiedBusinessCustomerOutputShape"
import CreateUnverifiedPersonalCustomerOutputShape from "./CreateUnverifiedPersonalCustomerOutputShape"
import FundInvestmentOutputShape from "./FundInvestmentOutputShape"
import IndexBankAccountsOutputShape from "./IndexBankAccountsOutputShape"
import IndexCustomersOutputShape from "./IndexCustomersOutputShape"
import IndexFailedTransactionsOutputShape from "./IndexFailedTransactionsOutputShape"
import IndexInvestorTransactionsOutputShape from "./IndexInvestorTransactionsOutputShape"
import IndexMyTransactionsOutputShape from "./IndexMyTransactionsOutputShape"
import IndexTransactionsOutputShape from "./IndexTransactionsOutputShape"
import ListMyCustomerBeneficialOwnersOutputShape from "./ListMyCustomerBeneficialOwnersOutputShape"
import ListSourceBankAccountsOutputShape from "./ListSourceBankAccountsOutputShape"
import ReadCustomerOutputShape from "./ReadCustomerOutputShape"
import ReadMyCustomerOutputShape from "./ReadMyCustomerOutputShape"
import ReadMyFundingBankAccountOutputShape from "./ReadMyFundingBankAccountOutputShape"
import ReadSourceLinkOutputShape from "./ReadSourceLinkOutputShape"
import ReadTransactionOutputShape from "./ReadTransactionOutputShape"
import ReadTransactionReportOutputShape from "./ReadTransactionReportOutputShape"
import RegisterExternalDistributionTransactionOutputShape from "./RegisterExternalDistributionTransactionOutputShape"
import RegisterExternalFundingTransactionOutputShape from "./RegisterExternalFundingTransactionOutputShape"
import RejectTransactionOutputShape from "./RejectTransactionOutputShape"
import RetryDistributionTransactionOutputShape from "./RetryDistributionTransactionOutputShape"
import SubmitMySourceMicroDepositsOutputShape from "./SubmitMySourceMicroDepositsOutputShape"
import UpdateMyCustomerVerificationStatusOutputShape from "./UpdateMyCustomerVerificationStatusOutputShape"
import UpdateMySourceVerificationStatusOutputShape from "./UpdateMySourceVerificationStatusOutputShape"
import UpdateRejectedTransactionAmountOutputShape from "./UpdateRejectedTransactionAmountOutputShape"
import UpgradeMyUnverifiedBusinessCustomerOutputShape from "./UpgradeMyUnverifiedBusinessCustomerOutputShape"
import UpgradeMyUnverifiedPersonalCustomerOutputShape from "./UpgradeMyUnverifiedPersonalCustomerOutputShape"
import UploadBusinessCustomerDocumentsOutputShape from "./UploadBusinessCustomerDocumentsOutputShape"
import UploadPersonalCustomerDocumentOutputShape from "./UploadPersonalCustomerDocumentOutputShape"
import VerifyMyCustomSourceOutputShape from "./VerifyMyCustomSourceOutputShape"

export {
  ApproveTransactionOutputShape,
  CertifyMyCustomerBeneficialOwnershipOutputShape,
  ConnectCustomSourceOutputShape,
  ConnectSourceOutputShape,
  CreateBankAccountOutputShape,
  CreateDistributionTransactionOutputShape,
  CreateImpersonatedReceiveOnlyBusinessCustomerOutputShape,
  CreateImpersonatedUnverifiedBusinessCustomerOutputShape,
  CreateImpersonatedUnverifiedPersonalCustomerOutputShape,
  CreateReceiveOnlyBusinessCustomerOutputShape,
  CreateUnverifiedBusinessCustomerOutputShape,
  CreateUnverifiedPersonalCustomerOutputShape,
  FundInvestmentOutputShape,
  IndexBankAccountsOutputShape,
  IndexCustomersOutputShape,
  IndexFailedTransactionsOutputShape,
  IndexInvestorTransactionsOutputShape,
  IndexMyTransactionsOutputShape,
  IndexTransactionsOutputShape,
  ListMyCustomerBeneficialOwnersOutputShape,
  ListSourceBankAccountsOutputShape,
  ReadCustomerOutputShape,
  ReadMyCustomerOutputShape,
  ReadMyFundingBankAccountOutputShape,
  ReadSourceLinkOutputShape,
  ReadTransactionOutputShape,
  ReadTransactionReportOutputShape,
  RegisterExternalDistributionTransactionOutputShape,
  RegisterExternalFundingTransactionOutputShape,
  RejectTransactionOutputShape,
  RetryDistributionTransactionOutputShape,
  SubmitMySourceMicroDepositsOutputShape,
  UpdateMyCustomerVerificationStatusOutputShape,
  UpdateMySourceVerificationStatusOutputShape,
  UpdateRejectedTransactionAmountOutputShape,
  UpgradeMyUnverifiedBusinessCustomerOutputShape,
  UpgradeMyUnverifiedPersonalCustomerOutputShape,
  UploadBusinessCustomerDocumentsOutputShape,
  UploadPersonalCustomerDocumentOutputShape,
  VerifyMyCustomSourceOutputShape
}

export const approveTransactionOperation = getOperation("transactions/ApproveTransaction")
export const certifyMyCustomerBeneficialOwnershipOperation = getOperation("transactions/CertifyMyCustomerBeneficialOwnership")
export const connectCustomSourceOperation = getOperation("transactions/ConnectCustomSource")
export const connectSourceOperation = getOperation("transactions/ConnectSource")
export const createBankAccountOperation = getOperation("transactions/CreateBankAccount")
export const createDistributionTransactionOperation = getOperation("transactions/CreateDistributionTransaction")
export const createImpersonatedReceiveOnlyBusinessCustomerOperation = getOperation("transactions/CreateImpersonatedReceiveOnlyBusinessCustomer")
export const createImpersonatedUnverifiedBusinessCustomerOperation = getOperation("transactions/CreateImpersonatedUnverifiedBusinessCustomer")
export const createImpersonatedUnverifiedPersonalCustomerOperation = getOperation("transactions/CreateImpersonatedUnverifiedPersonalCustomer")
export const createMyCustomerBeneficialOwnerOperation = getOperation("transactions/CreateMyCustomerBeneficialOwner")
export const createReceiveOnlyBusinessCustomerOperation = getOperation("transactions/CreateReceiveOnlyBusinessCustomer")
export const createUnverifiedBusinessCustomerOperation = getOperation("transactions/CreateUnverifiedBusinessCustomer")
export const createUnverifiedPersonalCustomerOperation = getOperation("transactions/CreateUnverifiedPersonalCustomer")
export const deleteBankAccountOperation = getOperation("transactions/DeleteBankAccount")
export const deleteExternalDistributionTransactionOperation = getOperation("transactions/DeleteExternalDistributionTransaction")
export const deleteExternalFundingTransactionOperation = getOperation("transactions/DeleteExternalFundingTransaction")
export const deleteMyCustomerBeneficialOwnerOperation = getOperation("transactions/DeleteMyCustomerBeneficialOwner")
export const deleteMySourceOperation = getOperation("transactions/DeleteMySource")
export const deleteTransactionOperation = getOperation("transactions/DeleteTransaction")
export const fundInvestmentOperation = getOperation("transactions/FundInvestment")
export const indexBankAccountsOperation = getOperation("transactions/IndexBankAccounts")
export const indexCustomersOperation = getOperation("transactions/IndexCustomers")
export const indexFailedTransactionsOperation = getOperation("transactions/IndexFailedTransactions")
export const indexInvestorTransactionsOperation = getOperation("transactions/IndexInvestorTransactions")
export const indexMyTransactionsOperation = getOperation("transactions/IndexMyTransactions")
export const indexTransactionsOperation = getOperation("transactions/IndexTransactions")
export const listMyCustomerBeneficialOwnersOperation = getOperation("transactions/ListMyCustomerBeneficialOwners")
export const listSourceBankAccountsOperation = getOperation("transactions/ListSourceBankAccounts")
export const readCustomerOperation = getOperation("transactions/ReadCustomer")
export const readMyCustomerOperation = getOperation("transactions/ReadMyCustomer")
export const readMyFundingBankAccountOperation = getOperation("transactions/ReadMyFundingBankAccount")
export const readSourceLinkOperation = getOperation("transactions/ReadSourceLink")
export const readTransactionOperation = getOperation("transactions/ReadTransaction")
export const registerExternalDistributionTransactionOperation = getOperation("transactions/RegisterExternalDistributionTransaction")
export const registerExternalFundingTransactionOperation = getOperation("transactions/RegisterExternalFundingTransaction")
export const rejectTransactionOperation = getOperation("transactions/RejectTransaction")
export const retryDistributionTransactionOperation = getOperation("transactions/RetryDistributionTransaction")
export const retryFundingTransactionOperation = getOperation("transactions/RetryFundingTransaction")
export const submitMySourceMicroDepositsOperation = getOperation("transactions/SubmitMySourceMicroDeposits")
export const transferControllerCustomersOperation = getOperation("transactions/TransferControllerCustomers")
export const updateMyCustomerBeneficialOwnerOperation = getOperation("transactions/UpdateMyCustomerBeneficialOwner")
export const updateMyCustomerVerificationStatusOperation = getOperation("transactions/UpdateMyCustomerVerificationStatus")
export const updateMySourceVerificationStatusOperation = getOperation("transactions/UpdateMySourceVerificationStatus")
export const updateRejectedTransactionAmountOperation = getOperation("transactions/UpdateRejectedTransactionAmount")
export const upgradeMyUnverifiedBusinessCustomerOperation = getOperation("transactions/UpgradeMyUnverifiedBusinessCustomer")
export const upgradeMyUnverifiedPersonalCustomerOperation = getOperation("transactions/UpgradeMyUnverifiedPersonalCustomer")
export const uploadBusinessCustomerDocumentsOperation = getOperation("transactions/UploadBusinessCustomerDocuments")
export const uploadMyCustomerBeneficialOwnerDocumentOperation = getOperation("transactions/UploadMyCustomerBeneficialOwnerDocument")
export const uploadPersonalCustomerDocumentOperation = getOperation("transactions/UploadPersonalCustomerDocument")
export const validateDistributionTransactionOperation = getOperation("transactions/ValidateDistributionTransaction")
export const verifyMyCustomSourceOperation = getOperation("transactions/VerifyMyCustomSource")
export const readTransactionReportOperation = getOperation("transactions/ReadTransactionReport")
