import { useState, useMemo, useRef } from "react"
import { usePapaParse } from "react-papaparse"
import { useOutletContext } from "react-router-dom"
import dayjs from "dayjs"

import authenticate from "@components/Authorization/authenticate/authenticate"
import authorizeUser from "@components/Authorization/authorize/authorizeUser"
import operationRequest from "@components/Authorization/request/operationRequest"
import { useAppContext } from "@components/AppContext"
import { downloadContent } from "@components/Import"
import { getCurrencyValue } from "@components/Amount"
import { getIndexOperationParameters } from "@modules/backstage/transactions/TransactionsTable/helpers/useIndexOperationParameters"
import { readTransactionReportOperation } from "@api/services/transactions"

const LABEL_DISTRIBUTIONS = "Total Distributions"
const LABEL_ORGANIZATION_ID = "Organization ID"
const LABEL_ORGANIZATION_NAME = "Organization Name"

const fileName = "oraganizations_report"


const useOrganizationsReport = (onReady) => {
  const shouldStopRef = useRef(false)
  const [ progress, setProgress ] = useState(0)

  const { jsonToCSV } = usePapaParse()
  const { organizations } = useOutletContext()
  const { showErrorMessage } = useAppContext()

  const operationsCount = useMemo(() =>
    organizations.length
  , [organizations])

  const start = async (dateRange) => {
    setProgress(0)
    shouldStopRef.current = false

    const [ startDay, endDay ] = dateRange
    const endDate = dayjs(endDay).endOf('month')
    const startDate = dayjs(startDay).startOf('month')

    const parameters = getIndexOperationParameters({
      dateRange: [startDate, endDate]
    })

    const getOragnizationDistributionsAmount = async (organizationId) => {
      const authenticationJwt = await authenticate(showErrorMessage, true)
      const [ authorization ] = await authorizeUser(authenticationJwt, organizationId)

      const headers = { authorization }
      const operation = readTransactionReportOperation

      const { data: transactionReport } = await operationRequest({
        headers,
        operation,
        parameters,
      })

      const { distributionsAmount } = transactionReport

      return distributionsAmount
    }

    const rows = []

    let index = 1

    for (const organization of organizations) {
      if (shouldStopRef.current) {
        return
      }

      const {
        id: organizationId,
        name: organizationName,
      } = organization

      const amount = await getOragnizationDistributionsAmount(organizationId)
      const distributionsAmount = getCurrencyValue(amount)

      const row = {
        [LABEL_ORGANIZATION_ID]: organizationId,
        [LABEL_ORGANIZATION_NAME]: organizationName,
        [LABEL_DISTRIBUTIONS]: distributionsAmount,
      }

      rows.push(row)

      setProgress(Math.ceil(index * 100 / operationsCount))
      index++
    }

    if (!shouldStopRef.current) {
      const content = jsonToCSV(rows)
      downloadContent(`${fileName}.csv`, content, 'text/csv', true)
      onReady()
    }
  }

  const stop = () =>
    shouldStopRef.current = true

  return [ progress, start, stop ]
}

export default useOrganizationsReport
