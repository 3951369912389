import PropTypes from "prop-types"


const properties = {
  createdAt: PropTypes.string.isRequired,
  createdBy: PropTypes.string,
  distributionsAmount: PropTypes.number.isRequired,
  endDate: PropTypes.string,
  equityRaisedAmount: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  startDate: PropTypes.string,
  updatedAt: PropTypes.string,
  updatedBy: PropTypes.string
}

const ReadTransactionReportOutputShapeShape = PropTypes.exact(properties)

export default ReadTransactionReportOutputShapeShape
export { properties }
