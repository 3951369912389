import {
  TRANSACTION_STATUS,
  TRANSACTION_STATUS_LABEL_MAP,
} from "@components/Domain"


const computeStatus = transaction => {
  let { status } = transaction

  const { isApproved, retryTransactionId } = transaction

  const isPending = status === TRANSACTION_STATUS.PENDING
  const isPendingApproval = isPending && !isApproved

  const isRetried = !!retryTransactionId

  if (isRetried) {
    status = TRANSACTION_STATUS.RETRIED
  }

  if (isPendingApproval) {
    status = TRANSACTION_STATUS.PENDING_APPROVAL
  }

  const label = TRANSACTION_STATUS_LABEL_MAP[status] || status

  return label
}

export default computeStatus